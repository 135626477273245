<template>
  <div class="">
    <div class="breadcrumb-m">
      <div class="left">
        <Breadcrumb>
          <BreadcrumbItem to="/">主控台</BreadcrumbItem>
          <BreadcrumbItem>站内信管理</BreadcrumbItem>
          <BreadcrumbItem>发送站内信</BreadcrumbItem>
        </Breadcrumb>
      </div>
    </div>
    <edit-mail></edit-mail>
  </div>
</template>
<script>
import editMail from '@/views/notice/editMail'
export default {
  name: "SendMail",
  components:{
    editMail
  }
}
</script>